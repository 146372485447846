import React from 'react';
import TextToggle from '../../components/text-toggle/text-toggle';
import WoodHome from '../../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import Advantages from '../../components/advantages/advantages';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import Data from '../../data/data';
import PriceList from '../../components/price-list/price-list';


const RestavratsiyaVentsovSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Реставрация деревянных домов', url:'/restavratsiya-derevyannyh-domov/', status:true},
    {name:'Замена венцов сруба ', url:'#', status:true}]
  return (
    <div>
        <SEO title="Замена венцов дома из бруса или сруба  и в Калининградской области"
            description="Опытные бригады в срок выполнят работы по реставрации венцов сруба  в Калининградской области.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Замена венцов дома в Кали&shy;нинград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-md-left">Замена венцов деревянного дома</h1>
                <p>
                Замена венцов деревянного дома или сруба может понадобиться в тех случаях когда какой либо ряд приходит в негодность в связи с тем что бревно или брус сгнивает или становится трухлявым, в этом случае его необходимо удалить а на его место установить новый, самостоятельно заменить нижний венец сруба самостоятельно не всегда получается, и единственной возможностью является обращение к специализированным организациям которые делают подобные работы.
                </p>

  <p>Зачастую, замену венцов совмещают с устройством, ремонтом или рестоврацией фундамента, если это не сделать то возникает риск повреждения геометрии здания. Самой распространённой работой является замена нижнего венца деревянного дома из бревна, ведь нижний ряд подвергается основной нагрузке и повреждается как от грунтовых вод через подпитку фундаментом, так и от снежных сугробов. По стоимости замена венцов гораздо дешевле чем полная переборка, к тому же, это единственный способ быстро сделать ремонт.
                </p>
                  
                  
                
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
          <Advantages />
         </Container>
           
        <FormQuickOrder className="mt-5" />
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default RestavratsiyaVentsovSruba;